/* Titles */
.title {
	font-size: 2.2rem;
	letter-spacing: -.03em;
}

.title-lg {
	font-weight: 600;
	font-size: 2.6rem;
	letter-spacing: -.01em;
}

.title-sm {
	font-size: 2rem;
	letter-spacing: -.03em;
}

.title-xl {
	font-weight: 600;
	font-size: 3.6rem;
	letter-spacing: -.01em;
}

.title-light{
	font-weight: 300!important;
}

  
  .dropcap::before {
	content: "|";
	display: inline-block;
	width: 0.2em; /* Adjust the width of the vertical bar */
	height: 100%; /* Set the height to 100% of the line height */
	margin-right: 0.5rem; /* Adjust the spacing between the bar and text */
	color: #3498db; /* Blue color for the bar */
  }


.heading {
	margin-bottom: 1.6rem;

	&.heading-flex {
		display: flex;
		flex-direction: column;
		text-align: center;
	}

	.title {
		margin-bottom: 0;
	}

	&.heading-center {
		text-align: center;

		.title {
			margin-bottom: 1.5rem;
		}

		.title-lg {
			margin-bottom: 2.4rem;
		}
	}
}

.title-separator {
	font-weight: 300;
	color: #cccccc;

	.text-white & {
		color: #777;
	}
}

.title-desc {
	color: #777;
	font-weight: 300;
	font-size: 1.4rem;
	line-height: 1.5;
	letter-spacing: -.01em;
	margin-bottom: 0;
}

.heading-right {
	margin-top: .5rem;
}

.title-link {
	font-weight: 500;
	transition: all .3s ease;

	&:hover,
	&:focus {
		box-shadow: 0 1px 0 0 $primary-color;
	}
}

.title-link i {
	font-size: 1.5rem;
}

.title-link i:last-child {
	margin-left: .5rem;
}

@include mq('md') {
	.title {
		font-size: 2.4rem;
	}

	.title-lg {
		font-size: 3rem;
	}
}

@include mq('lg') {
	.heading {
		&.heading-flex {
			align-items: center;
			flex-direction: row;
			text-align: left;
		}
	}
	
	.heading-right {
		margin-top: 0;
		margin-left: auto;
	}
}