// Import third-party styles
@import './scss/plugins/owl-carousel/owl.carousel.scss';
@import './scss/style.scss';

// Set default styles for the body
body {
  visibility: visible;
  opacity: 1;
  margin: 0;
  font-family: Poppins, "Helvetica Neue", sans-serif;
  height: 100%;
}

// Set default styles for the html element
html {
  height: 100%;
}

// Custom styles for tables
table {
  width: 100%;
}

.table.table-sm {
  td {
		padding-top: 0.4rem;
		padding-bottom: 0.4rem;
	}
}


//FORM
.form-control {
  &+.required {
    margin-bottom: 2rem;
    margin-top: -1rem;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef !important;
  opacity: 1 !important;
}


// Styles for .mat-form-field
.mat-form-field {
  width: 100%;

  &.col-sm-2,
  &.col-sm-3,
  &.col-sm-4,
  &.col-sm-5,
  &.col-sm-6,
  &.col-sm-7,
  &.col-sm-8,
  &.col-sm-9 {
    //background-color: orange !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

// Styles for .mat-checkbox-layout .mat-checkbox-label
.mat-checkbox-layout .mat-checkbox-label {
  white-space: normal;
}

// Styles for .mat-snack-bar-container
.mat-snack-bar-container {
  background-color: #ffffff !important;
  color: #000000 !important;
  margin-top: 30% !important;
  z-index: 999;

  &.alert p,
  &.alert div {
    color: #ff0000 !important;
  }

  &.info p,
  &.info div {
    color: #17a2b8 !important;
  }

  .close {
    color: #333333 !important;
    top: 10px;
  }
}

//mofificato per menu tree (categorie)
.filter-items-count {
  .widget {
    .widget-sub-item {
      margin-left: 3rem;
      margin-bottom: 1.5rem;

      :first-child {
        margin-top: 1.5rem;
      }
    }
  }

  .item-count {
    top: 0;
  }
}

// modificato per farci stare etichetta in italiano (più lunga)
.product-details-action {
  .btn-cart {
    max-width: 210px;
  }
}

.details-filter-row {
  .form-control {
    min-width: 142px;
  }
}

.stock-colors {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: .7rem;
  padding-bottom: .5rem;
  position: relative;
  display: block;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: .2rem solid #fff;
  margin: 0 .2rem .3rem;
}

/*TAG */
.filter-colors {
  a {
    border: 0.1rem solid #ddd;
  }
}

.ambra {
  background-color: #FFBF00;
}

.aranciobianco {
  background: linear-gradient(90deg, #FFA500 50%, #ffffff 50%);
}

.arancione {
  background-color: #FFA500;
}

.bianco {
  background-color: #FFFFFF;
}

.blu {
  background-color: #0000FF;
}

.blubianco {
  background: linear-gradient(90deg, #0000FF 50%, #ffffff 50%);
}

.blughiaccio {
  background-color: #66CCFF;
}

.fucsia {
  background-color: #FF00FF;
}

.fucsiabianco {
  background: linear-gradient(90deg, #FF00FF 50%, #ffffff 50%);
}

.giallo {
  background-color: #FFFF00;
}

.giallobianco {
  background: linear-gradient(90deg, #FFFF00 50%, #ffffff 50%);
}

.giallolimone {
  background-color: #FFF44F;
}

.giallooro {
  background-color: #FFD700;
}

.nero {
  background-color: #000000;
}

.oro {
  background-color: #FFD700;
}

.rosa {
  background-color: #FFC0CB;
}

.rosso {
  background-color: #FF0000;
}

.rossobianco {
  background: linear-gradient(90deg, #FF0000 50%, #ffffff 50%);
}

.rossonero {
  background: linear-gradient(90deg, #FF0000 50%, #000000 50%);
}

.trasparente {
  background-color: transparent;
}

.verde {
  background-color: #00FF00;
}

.verdebianco {
  background: linear-gradient(90deg, #00FF00 50%, #ffffff 50%);
}


//box con sfondo // da usare con classe text-white
.banner-container {
  position: relative;
  background-blend-mode: multiply; background-color: rgba(0, 158, 227, 0.8);
}
.banner-about::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url('assets/images/banner-chi-siamo.jpg') center center/cover;
  opacity: 0.4;
  z-index: -1;
}

.with-shadow{
  box-shadow: -10px 0 30px rgba(51, 51, 51, .15);
}

.text-black{color: #000000!important;}